/**
 * @generated SignedSource<<fa5a8223b2d88ce73a1f8f698fa5e7c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedBottomModule_bottomModule$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SharedBottomModuleContent_bottomModule">;
  readonly " $fragmentType": "SharedBottomModule_bottomModule";
}>;
export type SharedBottomModule_bottomModule$key = ReadonlyArray<{
  readonly " $data"?: SharedBottomModule_bottomModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedBottomModule_bottomModule">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SharedBottomModule_bottomModule",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedBottomModuleContent_bottomModule"
    }
  ],
  "type": "BottomModule",
  "abstractKey": null
};

(node as any).hash = "4376bd5e251ec51431d36d8ac0677f78";

export default node;
