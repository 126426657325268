import { FormattedMessage, defineMessages } from 'dibs-react-intl';

export const frequentlyAskedQuestions = header => (
    <FormattedMessage
        id="buy.frequentlyAskedQuestions"
        defaultMessage="Questions About {header}"
        values={{ header }}
    />
);

export const messages = defineMessages({
    noInventoryAvailable: {
        id: 'buy.noInventoryAvailable',
        defaultMessage: 'No exact matches, view similar items and recent sales below',
    },
});
