/**
 * @generated SignedSource<<15c495a90e60ca8eaa1ef38c6c5859f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedFAQAuthor_author$data = {
  readonly sellerPreferences: {
    readonly label: string | null;
    readonly scrollLogo: string | null;
    readonly urlLabel: string | null;
  } | null;
  readonly " $fragmentType": "SharedFAQAuthor_author";
};
export type SharedFAQAuthor_author$key = {
  readonly " $data"?: SharedFAQAuthor_author$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedFAQAuthor_author">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedFAQAuthor_author",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerPreferences",
      "kind": "LinkedField",
      "name": "sellerPreferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scrollLogo",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "52386ea156460eb146340ec01f854a85";

export default node;
