import { FunctionComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';
import { SharedFAQAnswer } from './SharedFAQAnswer';
import { ExpandingAreaWithReadMore } from 'dibs-elements/exports/ExpandingArea';
import { Link } from 'dibs-elements/exports/Link';

import { SharedFAQQuestion_question$data } from './__generated__/SharedFAQQuestion_question.graphql';
import styles from './SharedFAQQuestion.scss';

type Props = {
    question: SharedFAQQuestion_question$data | null | undefined;
    className?: string;
};

export const SharedFAQQuestionComponent: FunctionComponent<Props> = ({ question, className }) => {
    const { title, url, answerCount, id, answers } = question || {};
    if (title && url && id && answers && answerCount) {
        return (
            <li className={classnames(styles.question, className)} key={id}>
                <div className={styles.questionHeader}>
                    <Link href={url} className={styles.questionTitle} dataTn="faq-question">
                        {title}
                    </Link>
                    <div className={styles.answerCount} data-tn="faq-answer-count">
                        <FormattedMessage
                            id="SharedFAQ.numAnswers"
                            defaultMessage="{answerCount, plural, one {# Answer} other {# Answers}}"
                            values={{ answerCount }}
                        />
                    </div>
                </div>
                <ExpandingAreaWithReadMore buttonClass={styles.button} collapsedHeight={206}>
                    {answers.map(answer =>
                        answer?.serviceId ? (
                            <SharedFAQAnswer answer={answer} key={answer.serviceId} />
                        ) : null
                    )}
                </ExpandingAreaWithReadMore>
            </li>
        );
    }
    return null;
};

export const SharedFAQQuestion = createFragmentContainer(SharedFAQQuestionComponent, {
    question: graphql`
        fragment SharedFAQQuestion_question on QuestionType {
            id
            url
            title
            answerCount
            answers {
                serviceId
                ...SharedFAQAnswer_answer
            }
        }
    `,
});
