import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

import styles from './SharedBottomModuleContent.scss';

const SharedBottomModuleContentComponent = ({ bottomModule }) => {
    return (
        <>
            {bottomModule.map(item =>
                item.title && item.description ? (
                    <Fragment key={item.title}>
                        <HeadingLevel>
                            {Heading => <Heading className={styles.title}>{item.title}</Heading>}
                        </HeadingLevel>
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                    </Fragment>
                ) : null
            )}
        </>
    );
};

SharedBottomModuleContentComponent.propTypes = {
    bottomModule: PropTypes.any,
};

export const SharedBottomModuleContent = createFragmentContainer(
    SharedBottomModuleContentComponent,
    {
        bottomModule: graphql`
            fragment SharedBottomModuleContent_bottomModule on BottomModule @relay(plural: true) {
                title
                description
            }
        `,
    }
);
