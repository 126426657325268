/**
 * @generated SignedSource<<5519b5ec2fc29687218826ba8d6c47e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedFAQAnswer_answer$data = {
  readonly author: {
    readonly " $fragmentSpreads": FragmentRefs<"SharedFAQAuthor_author">;
  } | null;
  readonly formattedCreatedDate: string | null;
  readonly serviceId: string;
  readonly text: string | null;
  readonly " $fragmentType": "SharedFAQAnswer_answer";
};
export type SharedFAQAnswer_answer$key = {
  readonly " $data"?: SharedFAQAnswer_answer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedFAQAnswer_answer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedFAQAnswer_answer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedCreatedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedFAQAuthor_author"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AnswerType",
  "abstractKey": null
};

(node as any).hash = "1a14c7f321d032ae1c7b6a3cffe6f991";

export default node;
