import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './SharedBottomModuleSpacer.scss';

export const SharedBottomModuleSpacer = ({ className, bottomModule, children }) => {
    if (!bottomModule || !bottomModule.length) {
        return null;
    }
    return <div className={classnames(styles.wrapper, className)}>{children}</div>;
};

SharedBottomModuleSpacer.propTypes = {
    className: PropTypes.string,
    bottomModule: PropTypes.array,
    children: PropTypes.node,
};
