import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { ExpandingAreaWithReadMore } from 'dibs-elements/exports/ExpandingArea';
import { SharedBottomModuleSpacer } from './SharedBottomModuleSpacer';
import { SharedBottomModuleContent } from './SharedBottomModuleContent';

const SharedBottomModuleComponent = ({ bottomModule, className }) => {
    return (
        <SharedBottomModuleSpacer className={className} bottomModule={bottomModule}>
            <ExpandingAreaWithReadMore collapsedHeight={165}>
                <SharedBottomModuleContent bottomModule={bottomModule} />
            </ExpandingAreaWithReadMore>
        </SharedBottomModuleSpacer>
    );
};

SharedBottomModuleComponent.propTypes = {
    bottomModule: PropTypes.array,
    className: PropTypes.string,
};

export const SharedBottomModule = createFragmentContainer(SharedBottomModuleComponent, {
    bottomModule: graphql`
        fragment SharedBottomModule_bottomModule on BottomModule @relay(plural: true) {
            ...SharedBottomModuleContent_bottomModule
        }
    `,
});
